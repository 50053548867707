const videos = [
  {
    id: 'food-overview',
    category: 'food',
    title: 'Overview',
    blurb: 'KounterTop quickly digitizes your prep lists and recipes. Ensures consistency and efficiency for daily food prep',
    url: 'https://www.youtube.com/embed/Fx5X6kLLVZY',
    points: [
      'Keep staff accountable',
      'Records who made it, how long it took to make and what recipe used to make it',
      'Automatically adjust pars based on historical data',
      'Inventory checklist with multiple storage locations',
      'Tracks all your waste in one spot',
      'Receive instant email and mobile alerts for was on big ticket items',
      'Feature7',
      'Feature8',
    ],
  },
  {
    id: 'food-who-made-this-wrong',
    category: 'food',
    title: 'WHO MADE THIS WRONG?',
    blurb: 'KounterTop does great stuff. Its so great, really. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    url: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
    points: [
      'Feature1',
      'Feature2',
      'Feature3',
      'Feature4',
      'Feature5',
      'Feature6',
      'Feature7',
      'Feature8',
    ],
  },
  {
    id: 'food-all-your-recipies-in-one-place',
    category: 'food',
    title: 'ALL YOUR RECIPES IN ONE PLACE',
    blurb: 'KounterTop does great stuff. Its so great, really. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    url: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
    points: [
      'Feature1',
      'Feature2',
      'Feature3',
      'Feature4',
      'Feature5',
      'Feature6',
      'Feature7',
      'Feature8',
    ],
  },
  {
    id: 'food-steal3',
    category: 'food',
    title: 'WHERE\'D THE RECIPE BOOK GO?',
    blurb: 'KounterTop does great stuff. Its so great, really. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    url: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
    points: [
      'Feature1',
      'Feature2',
      'Feature3',
      'Feature4',
      'Feature5',
      'Feature6',
      'Feature7',
      'Feature8',
    ],
  },
  {
    id: 'food-steal4',
    category: 'food',
    title: 'Title5',
    blurb: 'KounterTop does great stuff. Its so great, really. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    url: 'https://www.youtube.com/embed/dQw4w9WgXcQ',
    points: [
      'Feature1',
      'Feature2',
      'Feature3',
      'Feature4',
      'Feature5',
      'Feature6',
      'Feature7',
      'Feature8',
    ],
  },
];

module.exports = videos;
