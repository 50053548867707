import { withStyles } from 'react-jss';
import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import videos from '../content/landing-videos';

const demoRequestStrings = [
  'Request A Demo',
  'Contact Us Now',
  'Free Demo',
  'Easy To Set Up',
  'Get a Call Back',
];

const styles = (theme) => ({
  main: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  contactEmailContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  contactInput: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    margin: '1rem',
    padding: '1rem 0.8rem',
    transition: 'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, margin 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    '&:focus': {
      borderColor: theme.colorPrimary,
      borderWidth: '2px',
      margin: '0.8rem',
      outline: '0',
    },
  },
  contactSendContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1rem',
  },
  header: {
    textAlign: 'center',
  },
  featureListItem: {
    marginBottom: '0.35rem',
  },
});

const styleFeatureCard = {
  display: 'flex',
  flex: '1 1 320px',
  minWidth: '320px',
  alignItems: 'center',
};

class LandingTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.handleDemoRequestClick = this.handleDemoRequestClick.bind(this);

    this.state = {
      demoRequestStringIndex: 0,
    };

    this.emailInput = React.createRef();
  }

  componentDidMount() {
    /*const featuredId = 'food-all-your-recipies-in-one-place';

    const videoIndex = Math.max(0, videos.findIndex((video) => video.id === featuredId));
    const videoInfo = videos.splice(videoIndex, 1)[0];
    this.setState({ featuredVideoInfo });*/

    this.requestDemoChangeInterval = setInterval(() => {
      this.setState((prevState) => {
        let {
          demoRequestStringIndex,
        } = prevState;

        demoRequestStringIndex += 1;

        if (demoRequestStringIndex >= demoRequestStrings.length) {
          demoRequestStringIndex = 0;
        }

        return { demoRequestStringIndex };
      });
    }, 1000);
  }

  componentWillUnmount() {
    if (this.requestDemoChangeInterval) {
      clearInterval(this.requestDemoChangeInterval);
    }
  }

  handleDemoRequestClick() {
    if (this.emailInput.current) {
      this.emailInput.current.focus();
    }
  }

  render() {
    const {
      demoRequestStringIndex,
    } = this.state;
    const {
      classes,
      pageContext,
    } = this.props;

    const { featuredVideoInfo } = pageContext;

    const relevantVideos = videos.filter((videoInfo) => (
      videoInfo.category === featuredVideoInfo.category
      && videoInfo.id !== featuredVideoInfo.id
    ));

    return (
      <Layout>
        <SEO title={featuredVideoInfo.title} />
        <h1 className={classes.header}>
          {featuredVideoInfo.title}
        </h1>
        <div className={classes.main}>
          <Card style={{ flexDirection: 'column', ...styleFeatureCard }}>
            <div
              style={{
                display: 'flex',
              }}
            >
              <iframe
                width="720"
                height="405"
                src={featuredVideoInfo.url}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullscreen
                title="test"
              />
              <div
                style={{
                  width: '400px',
                  marginLeft: '1rem',
                }}
              >
                <h1 className={classes.header}>
                  {demoRequestStrings[demoRequestStringIndex]}
                </h1>
                <form action="/api_public/sendContactForm" method="post">
                  <div className={classes.contactEmailContainer}>
                    <input
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                      className={classes.contactInput}
                      name="email"
                      placeholder="Your email"
                      ref={this.emailInput}
                      type="email"
                    />
                    <input
                      className={classes.contactInput}
                      name="name"
                      placeholder="Your name"
                      type="text"
                    />
                    <textarea
                      className={classes.contactInput}
                      name="notes"
                      placeholder="Notes"
                    />
                  </div>
                  <div
                    className={classes.contactSendContainer}
                  >
                    <Button type="submit">
                      SEND
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <div>
              <ul style={{ columnCount: '4', columnGap: '3rem' }}>
                {featuredVideoInfo.points.map((point) => (
                  <li className={classes.featureListItem}>
                    {point}
                  </li>
                ))}
              </ul>
            </div>
          </Card>
          {relevantVideos.map((video, i) => (
            <Card style={styleFeatureCard}>
              <iframe
                width="720"
                height="405"
                src={video.url}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullscreen
                title="test"
                style={{
                  order: (i % 2) ^ 1,
                }}
              />
              <div
                style={{
                  width: '400px',
                  marginLeft: '1rem',
                  order: (i % 2) ^ 0,
                }}
              >
                <div>
                  {video.blurb}
                </div>
                <ul>
                  {video.points.map((point) => (
                    <li className={classes.featureListItem}>
                      {point}
                    </li>
                  ))}
                </ul>
                <Button onClick={this.handleDemoRequestClick} style={{ width: '100%' }}>
                  {demoRequestStrings[demoRequestStringIndex]}
                </Button>
              </div>
            </Card>
          ))}
          <Card style={{ flexDirection: 'column', ...styleFeatureCard }}>
            <h1 className={classes.header}>
              {demoRequestStrings[demoRequestStringIndex]}
            </h1>
            <form action="/api_public/sendContactForm" method="post">
              <div className={classes.contactEmailContainer}>
                <input
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                  className={classes.contactInput}
                  name="email"
                  placeholder="Your email"
                  type="email"
                />
                <input
                  className={classes.contactInput}
                  name="name"
                  placeholder="Your name"
                  type="text"
                />
                <textarea
                  className={classes.contactInput}
                  name="notes"
                  placeholder="Notes"
                />
              </div>
              <div
                className={classes.contactSendContainer}
              >
                <Button type="submit">
                  SEND
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </Layout>
    );
  }
}

export default withStyles(styles)(LandingTemplate);
